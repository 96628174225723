import type { FC } from "react";
import styled from "styled-components";
import { CloseIcon } from "./Icons";

interface ModalProps {
  title: string;
  message?: string;
  onClose: () => void;
}

export const ErrorModal: FC<ModalProps> = ({ title, message, onClose }) => {
  return (
    <Overlay>
      <ModalContainer>
        <Header>
          <CloseIcon onClick={onClose} />
        </Header>
        <Body>
          <Title>{title}</Title>
          {message && <Message>{message}</Message>}
        </Body>
        <Button onClick={onClose}>OK</Button>
      </ModalContainer>
    </Overlay>
  );
};

export default ErrorModal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
  padding: 10px; /* Responsive margin */
`;

const ModalContainer = styled.div`
  background: #1F1C1C; /* Dark background */
  padding: 20px 18px 30px 18px;
  border-radius: 10px;
  max-width: 335px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
`;

const Message = styled.p`
  font-size: 12px;
  margin: 10px 0 20px;
  text-align: center;
  color: #ADADAD;
`;

const Button = styled.button`
  background-image: linear-gradient(to right, #00E218, #FFE500);
  padding: 20px 10px;
  color: black;
  border-radius: 6px; /* Rounded button */
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: center;
  max-width: 300px;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, #c3ff00, #00f570); /* Slight hover color change */
  }
`;
