import { Center } from "@/components/Center";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useEntryDetails } from "@/hooks/soccer/useEntryDetails";
import { useSoccerRound } from "@/hooks/soccer/useSoccerRound";
import type { RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SelectCaptain } from "./SelectCaptain";
import { SelectEntry } from "./SelectEntry";
import { SelectPlayer } from "./SelectPlayer";

export type RoundPageType = "select-player" | "select-captain" | "entry";

export const RoundPage = () => {
  const { roundId } = useParams();
  const user = useUserContext();

  if (!roundId) throw new Error("roundId is required");

  const { entryDetails, setEntryDetails } = useEntryDetails(user.id);
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting round", message);
    },
    [openModal],
  );
  const { data, isLoading, mutate } = useSoccerRound(roundId, handleError);
  const [currentPage, setCurrentPage] = useState<RoundPageType>("select-player");

  useEffect(() => {
    if (currentPage === "select-player") {
      mutate();
    }
  }, [currentPage, mutate]);

  if (isLoading || !data)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  if (currentPage === "select-player")
    return (
      <SelectPlayer
        round={data.round}
        setCurrentPage={setCurrentPage}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
      />
    );
  if (currentPage === "select-captain")
    return (
      <SelectCaptain
        round={data.round}
        setCurrentPage={setCurrentPage}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
      />
    );
  if (currentPage === "entry")
    return (
      <SelectEntry
        round={data.round}
        setEntryDetails={setEntryDetails}
        entryDetails={entryDetails}
        setCurrentPage={setCurrentPage}
      />
    );
};
