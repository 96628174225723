import { Center } from "@/components/Center";
import PlayerStatBarChart from "@/components/Charts/PlayerStatBarChart";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useRecentFantasyPoints } from "@/hooks/soccer/useRecentFantasyPoints";
import type { Player } from "@/types/Player";
import { formatName } from "@/utils/name";
import { format } from "date-fns";

interface PlayerFantasyPointsProps {
  player: Player;
  roundId: string;
}

export const PlayerFantasyPoints: React.FC<PlayerFantasyPointsProps> = ({ player, roundId }) => {
  const { data, error, isLoading } = useRecentFantasyPoints(player.id, roundId);

  if (!roundId || !player || error) {
    return (
      <Center>
        <div className="text-red-500">
          {!player ? "No player selected" : !roundId ? "No round selected" : "Failed to load data"}
        </div>
      </Center>
    );
  }

  if (isLoading || !data)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  const fantasyPoints = data.recentFantasyPoints
    .filter((point) => Number.isFinite(point.fantasyPoint)) // remove non-finite values
    .reverse();

  return (
    <div className="w-full h-full p-4">
      <div className="fixed top-5 w-[80%]">
        <h2 className="text-white text-center text-[14px] w-full">{formatName(player.name)}</h2>
      </div>
      <div className="relative mb-4">
        <img src={player.profileImageUrl} alt={player.name} className="w-full h-64 object-cover" />
      </div>
      {/* TODO: Add performance pentagon chart */}
      <div className="mt-4">
        {fantasyPoints.length > 0 && (
          <div className="mt-4 p-4">
            <h2 className="text-white text-center text-[14px] w-full">Achievements(pt)</h2>
            <PlayerStatBarChart
              labels={fantasyPoints.map((point, index) => {
                if (index === 0 || index === fantasyPoints.length - 1) return format(point.startDate, "MMM dd");
                return ""; // Empty labels for the other data points
              })}
              data={fantasyPoints.map((fp) => fp.fantasyPoint)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
