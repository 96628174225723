import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { CustomModal } from "@/components/CustomModal";
import { CheckSuccessfulIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { usePostWithdrawalRequest } from "@/hooks/payment/useWithdrawal";
import { useTelegram } from "@/hooks/useTelegram";
import { getErrorMessage } from "@/utils/error";
import { fromNano, toNano } from "@ton/ton";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useState } from "react";
import { CustomInput } from "./CustomInput";
import { OptionButton } from "./OptionButton";

const withdrawOptions = [25, 50, 100];

const MIN_WITHDRAW_AMOUNT = 0.2;

export const Withdraw = () => {
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const { openModal: openErrorModal } = useErrorModalContext();
  const { initDataRaw } = useTelegram();

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (amount: string) => {
    if (!Number.isNaN(amount)) {
      setWithdrawAmount(Number.parseFloat(amount));
    }
  };
  const user = useUserContext();
  const userTon = fromNano(user.nanoTon);
  const { trigger, isMutating, error: withdrawalError } = usePostWithdrawalRequest();

  const isBtnDisabled = withdrawAmount < MIN_WITHDRAW_AMOUNT || withdrawAmount > Number(userTon);

  const onClickWithdraw = async () => {
    if (isMutating) {
      return;
    }
    if (!initDataRaw) {
      openErrorModal("Failed to get telegram initial data, please make sure you have connected your telegram");
      return;
    }
    if (!address) {
      openErrorModal("Failed to get wallet address, please make sure you have connected your wallet");
      return;
    }
    setIsModalOpen(true);
    const req = {
      destUserAddress: address,
      nanoTon: toNano(withdrawAmount).toString(),
      userId: user.id,
      initDataRaw,
    };
    await trigger(req);
    setWithdrawAmount(0);
  };

  return (
    <div className="flex flex-col gap-3 h-full pt-4">
      <p className="text-white text-xs">Available: {userTon} Ton</p>
      <div className="flex items-end gap-2">
        <CustomInput value={withdrawAmount} onChange={handleChange} />
        <p className="text-white">Ton</p>
      </div>
      <div className="flex gap-2 items-center">
        {withdrawOptions.map((option) => (
          <OptionButton
            isPercent
            option={option}
            onClick={() => setWithdrawAmount(Number(userTon) * (option / 100))}
            key={option}
          />
        ))}
      </div>
      <p className="text-[#ADADAD] text-xs">Minimal withdraw: {MIN_WITHDRAW_AMOUNT}TON</p>
      <div className="fixed bottom-6 left-0 flex flex-col justify-end w-full px-6">
        {address ? (
          <div className="flex items-center justify-between w-full mb-3">
            <div className="flex items-center gap-2">
              <CheckSuccessfulIcon />
              <div>
                <p className="text-xs text-icon">Wallet address</p>
                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-white text-xs w-52">{address}</p>
              </div>
            </div>
            <button
              type="button"
              className="px-4 py-2.5 rounded-lg bg-gray-500 text-white text-xs"
              onClick={() => tonConnectUI.disconnect()}
            >
              Disconnect
            </button>
          </div>
        ) : (
          <p className="text-white text-xs text-center mb-3">Connect wallet you want to withdraw to</p>
        )}

        {address ? (
          <PrimaryButton
            className="w-full align-end"
            text="CONTINUE"
            onClick={onClickWithdraw}
            disabled={isBtnDisabled}
          />
        ) : (
          <PrimaryButton
            className="w-full align-end"
            text="CONNECT WALLET"
            onClick={() => {
              tonConnectUI.openModal();
            }}
          />
        )}
      </div>

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex flex-col text-white text-center">
          <div className="flex flex-col text-white text-center justify-center items-center gap-3">
            {isMutating ? (
              <LoadingSpinner />
            ) : withdrawalError ? (
              <p className="mt-4">{getErrorMessage(withdrawalError)}</p>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <img className="w-16 h-16" src="/assets/success-icon.svg" alt="success icon" />
                <p>Withdraw successfully initiated!</p>
              </div>
            )}
          </div>
          <PrimaryButton
            className="w-full mt-10"
            text="Close"
            onClick={() => setIsModalOpen(false)}
            disabled={isMutating}
          />
        </div>
      </CustomModal>
    </div>
  );
};
