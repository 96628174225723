import { CheckIcon, CheckSuccessfulIcon } from "@/components/Icons";
import type { PlayerResult } from "@/types/result";
import { formatName } from "@/utils/name";
import { useState } from "react";
import { setRankColor } from "./UserTable";

interface PlayerTabProps {
  players: PlayerResult[];
}

export const PlayerTable = ({ players }: PlayerTabProps) => {
  const [isOnlyYourTeam, setIsOnlyYourTeam] = useState(false);

  return (
    <div>
      <div className="flex gap-1 py-5 hover:cursor-pointer w-fit" onClick={() => setIsOnlyYourTeam(!isOnlyYourTeam)}>
        {isOnlyYourTeam ? <CheckSuccessfulIcon className="w-4 h-4" /> : <CheckIcon className="w-4 h-4" />}
        <p className="text-xs text-icon">Show only your team</p>
      </div>
      <div className="flex flex-col">
        {players
          .sort((a, b) => b.fantasyPoint - a.fantasyPoint)
          .map((player, index) => (
            <PlayerRow {...player} rank={index + 1} key={player.id} />
          ))}
      </div>
    </div>
  );
};

interface PlayerRowProps {
  name: string;
  fantasyPoint: number;
  rank: number;
}

const PlayerRow = ({ name, fantasyPoint, rank }: PlayerRowProps) => {
  return (
    <div className="flex justify-between items-center w-full px-4 py-1.5 text-icon">
      <div className="flex items-center gap-4 w-full">
        <p className={setRankColor(rank)}>{rank}</p>
        {/* TODO: need image from backend */}
        {/* <img className="rounded-lg" src={image} alt={name} /> */}
        <div className="flex flex-col gap-2 w-full">
          <p className="text-sm">{formatName(name)}</p>
          <div
            className={`h-0.5 ${fantasyPoint < 0 ? "bg-red-500" : "bg-icon"}`}
            style={{ width: `${Math.abs(fantasyPoint)}%`, boxShadow: rank === 1 ? "0px 0px 9px 0px #FF8A00" : "none" }}
          />
        </div>
      </div>
      <p className={`flex justify-end w-[62px] ${rank === 1 ? "text-base" : "text-sm"}`}>{fantasyPoint.toFixed(2)}pt</p>
    </div>
  );
};
