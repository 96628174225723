import { Address, beginCell } from "@ton/ton";
import type { CHAIN, SendTransactionRequest } from "@tonconnect/ui-react";
import { addSeconds, getUnixTime } from "date-fns";

/**
 * Prepares a transaction object with a comment for sending TONs.
 * @param destinationAddress - The address to send the TONs to.
 * @param nanoTon - The amount to send in NanoTONs.
 * @param transactionComment - The comment to attach to the transaction.
 * @returns A transaction object with a validUntil deadline and messages.
 */
const prepareTransactionWithComment = (
  destinationAddress: string,
  nanoTon: string,
  transactionComment: string,
  network: CHAIN,
): SendTransactionRequest => {
  let nanoTonBigInt: bigint;
  try {
    nanoTonBigInt = BigInt(nanoTon);
  } catch (error) {
    throw new Error("nanoTon must be a valid numeric string");
  }
  if (nanoTonBigInt <= 0n) {
    throw new Error("nanoTon must be greater than 0");
  }
  // Convert address to raw format
  let rawAddress: string;
  try {
    const parsedAddress = Address.parse(destinationAddress);
    rawAddress = parsedAddress.toString();
  } catch (error: any) {
    throw new Error(`Invalid TON address: ${error.message}`);
  }

  const body = beginCell().storeUint(0, 32).storeStringTail(transactionComment).endCell();

  return {
    validUntil: getUnixTime(addSeconds(new Date(), 30)), // Deadline for tx to be processed in a block
    network,
    messages: [
      {
        address: rawAddress,
        amount: nanoTon,
        payload: body.toBoc().toString("base64"),
      },
    ],
  };
};

export default prepareTransactionWithComment;
