import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import type React from "react";
import { Bar } from "react-chartjs-2";

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface PlayerStatBarChartProps {
  labels: string[];
  data: number[];
}

const PlayerStatBarChart: React.FC<PlayerStatBarChartProps> = ({ labels, data }) => {
  const positiveData = data.map((data) => Math.abs(data));

  const getGradients = (ctx: CanvasRenderingContext2D, barTop: number, barBottom: number) => {
    const positiveGradient = ctx.createLinearGradient(0, barBottom, 0, barTop);
    positiveGradient.addColorStop(0, "#064027");
    positiveGradient.addColorStop(1, "#00E218");

    const negativeGradient = ctx.createLinearGradient(0, barBottom, 0, barTop);
    negativeGradient.addColorStop(0, "rgba(255, 0, 0, 0.4)");
    negativeGradient.addColorStop(1, "rgba(255, 0, 0, 0.8)");

    return { positiveGradient, negativeGradient };
  };

  const getMaxYTick = (data: number[]): number => {
    const maxMagnitude = Math.max(...data.map(Math.abs));
    const max = Math.ceil(maxMagnitude / 25) * 25;
    return Math.max(25, max);
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: positiveData,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return data[context.dataIndex] < 0 ? "rgba(255, 0, 0, 0.7)" : "rgba(0, 128, 0, 0.7)";
          }

          const yScale = chart.scales.y;
          const barValue = positiveData[context.dataIndex];
          const barTop = yScale.getPixelForValue(barValue);
          const barBottom = yScale.getPixelForValue(0);
          const { positiveGradient, negativeGradient } = getGradients(ctx, barTop, barBottom);
          return data[context.dataIndex] < 0 ? negativeGradient : positiveGradient;
        },
        borderWidth: 1,
        barThickness: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          color: "#ADADAD",
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: getMaxYTick(data),
        ticks: {
          stepSize: 25,
          color: "#ADADAD",
        },
        grid: {
          color: (context: { tick: { value: number } }) => {
            if (context.tick.value % 25 === 0) {
              return "rgba(255, 255, 255, 0.10)";
            }
            return "transparent";
          },
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disabling tooltips
      },
    },
  };

  return (
    <div className="w-full h-full">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default PlayerStatBarChart;
