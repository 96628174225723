import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useRoundEntryUnits } from "@/hooks/soccer/useRoundEntryUnits";
import type { RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { useCallback } from "react";
import { RoundEntryUnits } from "./RoundEntryUnits";

const getInitials = (fullName: string) => {
  return fullName
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase();
};

export const MyPage = () => {
  const user = useUserContext();
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching entries", message);
    },
    [openModal],
  );
  const { data, isLoading } = useRoundEntryUnits(user.id, handleError);

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <>
      <Container className="relative flex flex-col items-center justify-center">
        <BackButton className="absolute top-4 left-2" />
        <div className="relative flex flex-col">
          <img src="/assets/badge.svg" alt="badge icon" />
          <div className="absolute flex flex-col gap-2 items-center justify-center inset-0 -translate-y-3">
            <div className="flex justify-center items-center bg-icon w-14 h-14 rounded-full">
              <p className="text-xl">{getInitials(user.name)}</p>
            </div>
            <div className="flex gap-1 items-center">
              <p className="font-semibold">{user.name}</p>
              {/* TODO: how to determine this contry flag */}
              {/* <img src="/assets/japan-flag.svg" alt="flag" /> */}
            </div>
            <div className="w-[160px] h-0.5 bg-solid" />
            <div>
              <p className="text-xs font-medium">Total Wins</p>
              <p className="font-semibold">$10,000</p>
            </div>
            <div>
              <div className="flex items-center gap-3">
                <div className="text-center">
                  <p className="text-xs">Bets Placed</p>
                  <p className="font-semibold text-xs">
                    <span className="text-base">150</span>Bets
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-xs">Win Rate</p>
                  <p className="font-semibold text-xs">
                    <span className="text-base">35</span>%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full max-w-64 gap-2 text-center mb-8">
          <div className="text-icon text-xs">8,000 / 10,000</div>
          <div className="bg-solid h-1 flex items-center">
            <div
              className="bg-gradient-to-r from-[#606060] to-[#C6C6C6] h-1"
              style={{ width: `${(8000 / 10000) * 100}%` }}
            />
          </div>
          <div className="flex justify-center items-center gap-1">
            <p className="text-xs text-icon">Total Rank Points</p>
            <img src="/assets/goldIcon.svg" alt="gold icon" />
            <p className="text-white">8000</p>
            <InfoIcon onClick={() => {}} />
          </div>
        </div>
      </Container>
      <Tabs>
        <Tabs.Item title="Live">
          <RoundEntryUnits entryUnits={data.userRoundEntryUnits.filter((unit) => unit.status === "live")} />
        </Tabs.Item>
        <Tabs.Item title="Schedule">
          <RoundEntryUnits entryUnits={data.userRoundEntryUnits.filter((unit) => unit.status === "schedule")} />
        </Tabs.Item>
        <Tabs.Item title="Ended">
          <RoundEntryUnits
            entryUnits={data.userRoundEntryUnits.filter(
              (unit) => unit.status === "prizeDistributed" || unit.status === "cancelled",
            )}
          />
        </Tabs.Item>
      </Tabs>
    </>
  );
};
