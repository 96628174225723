import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import { TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useUserContext } from "@/contexts/UserContext";
import { useRoundEntryUnitResult } from "@/hooks/soccer/useRoundEntryUnitResult";
import { fromNano } from "@ton/ton";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PlayerTable } from "./PlayerTable";
import { UserTable } from "./UserTable";

export const ResultPage = () => {
  const { roundEntryUnitId } = useParams();
  const location = useLocation();
  //TODO: https://app.asana.com/0/1207835129689105/1208583149556866/f
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("matchName");

  if (!roundEntryUnitId) {
    return <div>Error: Invalid roundEntryUnitId</div>;
  }

  const [isPlayerTab, setIsPlayerTab] = useState(true);
  const user = useUserContext();
  const { data, isLoading } = useRoundEntryUnitResult(user.id, roundEntryUnitId);

  if (isLoading || data === undefined) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  const status = data.result.status;

  const prizeWon = data.result.prizeWon;
  const entries = data.result.entries;
  const players = data.result.players;

  const userEntry = entries.find((entry) => entry.userId === user.id);
  const userRank = userEntry ? userEntry.rank : null;

  const lowestRank = entries.sort((a, b) => b.rank - a.rank)[0]?.rank ?? 0;

  return (
    <>
      <Container>
        <div className="flex mb-2.5">
          <BackButton className="self-start" />
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex justify-center items-center w-full">
              <p className="text-icon pr-1.5">{name}</p>
              <InfoIcon onClick={() => {}} />
            </div>
            <p className="text-icon text-xs">{status}</p>
          </div>
        </div>
        {isPlayerTab && (
          <>
            <div className="flex justify-end w-full">
              <TonUsdPill />
            </div>
            <div className="flex w-full justify-center items-center py-3 gap-2">
              <div className="relative">
                <img src="/assets/account-bg.svg" alt="account bg" />
                {/* Here replace the account photo */}
                <img className="absolute inset-0 w-8 h-8 m-auto" src="/assets/cat.svg" alt="cat icon" />
              </div>
              <p className="text-sm text-icon">{user?.name}</p>
            </div>
            <div className="flex w-full justify-center gap-10 text-icon">
              {userRank && lowestRank && (
                <div className="flex flex-col items-center">
                  <p className="text-xs">Rank</p>
                  <p className="text-xl">
                    {userRank} / {lowestRank}
                  </p>
                </div>
              )}
              <div className="flex flex-col items-center">
                <p className="text-xs">Prizes</p>
                <div className="flex justify-center items-center gap-1">
                  <TonIcon />
                  <p className="text-xl">{prizeWon !== null ? fromNano(prizeWon) : ""}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
      <Tabs defaultIndex={0}>
        <Tabs.Item title="Player" onClick={() => setIsPlayerTab(true)}>
          <PlayerTable players={players} />
        </Tabs.Item>
        <Tabs.Item title="User" onClick={() => setIsPlayerTab(false)}>
          <UserTable entries={entries} />
        </Tabs.Item>
      </Tabs>
    </>
  );
};
