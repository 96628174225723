import { SportIcon } from "@/components/Icons/SportIcon";
import { TonIcon } from "@/components/Icons/TonIcon";
import { useUserContext } from "@/contexts/UserContext";
import { formatNanoTon } from "@/utils/formatNanoTon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SoccerRounds } from "./SoccerRounds";

type League = {
  id: number;
  name: string;
};

const LEAGUES = [
  {
    id: 1,
    name: "Premier League 🇬🇧",
  },
  {
    id: 2,
    name: "Bundesliga 🇩🇪",
  },
  {
    id: 3,
    name: "LaLiga 🇪🇸",
  },
  {
    id: 4,
    name: "Serie A 🇮🇹",
  },
];

export const HomePage = () => {
  const navigate = useNavigate();
  const [selectedLeague, setSelectedLeague] = useState<League>(LEAGUES[0]);
  const user = useUserContext();

  return (
    <div>
      <header className="flex flex-col px-2 py-4">
        <div className="flex items-center justify-between mb-3">
          <div className="flex justify-center items-center gap-1.5">
            <SportIcon img="/assets/sports/soccer.svg" category="soccer" />
            <SportIcon img="/assets/sports/americanfootball.svg" category="americanfootball" />
            <SportIcon img="/assets/sports/basketball.svg" category="basketball" />
            <SportIcon img="/assets/sports/baseball.svg" category="baseball" />
          </div>

          <div className="flex justify-center items-center gap-2.5">
            <div
              className="flex items-center justify-center gap-4 border border-chicago-700 rounded-2xl px-2 py-1.5 hover:cursor-pointer"
              onClick={() => navigate("/balance")}
              onKeyDown={(e) => {
                if (e.key === "enter") navigate("/balance");
              }}
            >
              <div className="flex justify-center items-center gap-1">
                <TonIcon />
                <button type="button" className=" text-sm text-white">
                  {formatNanoTon(user.nanoTon)}
                </button>
              </div>
              <img className="bg-button-background p-1.5 rounded-3xl" src="/assets/Union.svg" alt="plus icon" />
            </div>
            <img
              onClick={() => navigate("/my-page")}
              src="/assets/account-icon.svg"
              alt="Account"
              className="hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="flex gap-1.5 overflow-x-scroll custom-scrollbar">
          {LEAGUES.map((league) => (
            <LeaguePill
              onClick={() => setSelectedLeague(league)}
              isSelected={selectedLeague.id === league.id}
              league={league.name}
              key={league.id}
            />
          ))}
        </div>
      </header>
      <SoccerRounds />
    </div>
  );
};

const LeaguePill = ({ league, onClick, isSelected }: { league: string; onClick: () => void; isSelected: boolean }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center py-2 px-3 rounded-[30px] min-w-max hover:cursor-pointer
        ${isSelected ? "bg-gradient-to-r from-gradient-yellow to-gradient-green text-black" : "bg-button-background text-icon"}`}
    >
      <p className="text-xs">{league}</p>
    </div>
  );
};
