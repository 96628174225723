import { BackButton } from "@/components/Buttons/BackButton";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Container } from "@/components/Container";
import { CustomModal } from "@/components/CustomModal";
import { Deadline } from "@/components/Deadline";
import Tabs from "@/components/Tabs";
import { useUserContext } from "@/contexts/UserContext";
import { useCountDown } from "@/hooks/useCountDown";
import type { EntryDetails } from "@/types/EntryDetails";
import type { RoundDetails } from "@/types/RoundDetails";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { RoundPageType } from "..";
import { RoundInfoBanner } from "../RoundInfo/RoundInfoBanner";
import { SuccessModal, SufficientModal } from "../modals";
import { Entry } from "./Entry";
import { Prize } from "./Prize";
export interface EntryProps {
  round: RoundDetails;
  setEntryDetails: (body: EntryDetails) => void;
  entryDetails: EntryDetails;
  setCurrentPage: (page: RoundPageType) => void;
}

export const SelectEntry: React.FC<EntryProps> = (props) => {
  const { round, setCurrentPage } = props;

  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  const [totalFee, setTotalFee] = useState(0);
  const [canEnter, setCanEnter] = useState(false);
  const [isSufficientModalOpen, setIsSufficientModalOpen] = useState(false);
  const [isInsufficientModalOpen, setIsInsufficientModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  if (!round) return null;

  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));

  const user = useUserContext();
  const balance = Number.parseInt(user.nanoTon);

  const enterButton = (
    <PrimaryButton
      className="w-full align-end"
      text="ENTER"
      onClick={async () => {
        if (balance >= totalFee) {
          // go to buy modal
          setIsSufficientModalOpen(true);
        } else {
          // no funds
          setIsInsufficientModalOpen(true);
        }
      }}
      disabled={!canEnter}
    />
  );

  const onClickSuccessModal = () => {
    props.setEntryDetails({
      ...props.entryDetails,
      roundType: null,
      playerIds: [],
      entries: [],
      detail: {
        type: null,
        captainPlayerId: null,
        viceCaptainPlayerId: null,
      },
    });
    setCurrentPage("select-player");
  };

  return (
    <Container className="flex flex-col">
      <div className="flex mb-2.5">
        <BackButton onClick={() => setCurrentPage("select-captain")} />
        <RoundInfoBanner
          round={round}
          isRoundInfoTabsOpen={isRoundInfoTabsOpen}
          onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
          onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
        />
      </div>
      <Deadline deadlineTime={deadlineTime} />

      <Tabs>
        <Tabs.Item title="Entry">
          <Entry
            {...props}
            totalFee={totalFee}
            setTotalFee={setTotalFee}
            setCanEnter={setCanEnter}
            enterButton={enterButton}
          />
        </Tabs.Item>
        <Tabs.Item title="Prizes">
          <Prize totalFee={totalFee} enterButton={enterButton} {...props} />
        </Tabs.Item>
      </Tabs>

      <SufficientModal
        isOpen={isSufficientModalOpen}
        balance={balance}
        totalFee={totalFee}
        onClose={() => setIsSufficientModalOpen(false)}
        entryDetails={props.entryDetails}
        onSuccess={() => setIsSuccessModalOpen(true)}
      />

      <CustomModal isOpen={isInsufficientModalOpen} onClose={() => setIsInsufficientModalOpen(false)}>
        <div className="flex flex-col text-white text-center">
          <div className="flex flex-col text-white text-center">
            <p className="text-base">Insufficient funds, please deposit Ton.</p>
          </div>
          <PrimaryButton className="w-full mt-6" text="ADD FUNDS" onClick={() => navigate("/balance")} />
        </div>
      </CustomModal>

      <SuccessModal isOpen={isSuccessModalOpen} onClose={() => navigate("/")} onClick={onClickSuccessModal} />
    </Container>
  );
};
