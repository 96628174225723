import { stringify } from "querystring";
import { env } from "@/config/env";
import axios from "axios";

const createURL = (path: string) => {
  let apiUrl = env.API_URL;
  if (apiUrl.endsWith("/")) {
    apiUrl = apiUrl.slice(0, -1);
  }
  if (path.startsWith("/")) {
    return `${apiUrl}${path}`;
  }
  return `${apiUrl}/${path}`;
};

// FIXME: For ngrok free plan user
const headers = { headers: { "ngrok-skip-browser-warning": true } };

const RomanApi = {
  get: async <T, D = never>(path: string, data?: D) => {
    let url = createURL(path);
    if (data) {
      url += `?${stringify(data)}`;
    }
    const response = await axios.get<T>(url, headers);
    return response.data;
  },
  post: async <T, D>(path: string, data: D) => {
    const url = createURL(path);
    const response = await axios.post<T>(url, data, headers);
    return response.data;
  },
  put: async <T, D>(path: string, data: D) => {
    const url = createURL(path);
    const response = await axios.put<T>(url, data, headers);
    return response.data;
  },
  patch: async <T, D>(path: string, data: D) => {
    const url = createURL(path);
    const response = await axios.patch<T>(url, data, headers);
    return response.data;
  },
  delete: async <T>(path: string) => {
    const url = createURL(path);
    const response = await axios.delete<T>(url, headers);
    return response.data;
  },
};

export type RomanApiError = { message: string; response?: { data?: { message?: string } } };

export default RomanApi;
