import { Center } from "@/components/Center";
import { TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useSoccerEntryPrize } from "@/hooks/soccer/useSoccerEntryPrize";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { PrizeAmount } from "@/types/RoundEntryPrize";
import { getErrorMessage } from "@/utils/error";
import { fromNano } from "@ton/ton";
import ordinal from "ordinal";
import { useCallback } from "react";

export const Prize = ({ roundId }: { roundId: string }) => {
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching prizes", message);
    },
    [openModal],
  );
  const { data, isLoading } = useSoccerEntryPrize(roundId, handleError);

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  if (data.roundEntryPrize.length === 0) {
    return (
      <div className="flex justify-center items-center h-52">
        <p className="text-white">no data to show</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full py-4">
      <div className="flex justify-end mb-8">
        <TonUsdPill />
      </div>

      <Tabs>
        {data.roundEntryPrize.map((entry) => (
          <Tabs.Item title={`${fromNano(entry.entryFee)} Ton`} key={entry.roundEntryUnitId}>
            <PrizeContent prizes={entry.prize} totalPayOut={entry.totalPayOut} />
          </Tabs.Item>
        ))}
      </Tabs>
    </div>
  );
};

export const transformPrizeAmounts = (prizeAmounts: PrizeAmount[]) => {
  return prizeAmounts.flatMap(({ startRank, endRank, amount }) => {
    // For ranks 1-3, we need to expand them individually
    if (startRank <= 3) {
      const prizes = [];
      for (let rank = startRank; rank <= Math.min(endRank, 3); rank++) {
        prizes.push({
          rank: ordinal(rank),
          amount,
        });
      }

      return prizes;
    }

    // For ranks after 3, use ranges
    return [
      {
        rank: startRank === endRank ? ordinal(startRank) : `${ordinal(startRank)}-${ordinal(endRank)}`,
        amount,
      },
    ];
  });
};

const PrizeContent = ({ prizes, totalPayOut }: { prizes: PrizeAmount[]; totalPayOut: string }) => {
  const transformedPrizes = transformPrizeAmounts(prizes);

  return (
    <div>
      <div className="flex justify-between py-4">
        <p className="textsm text-white">Prize Payouts</p>
        <div className="flex gap-1">
          <TonIcon />
          <p className="text-white tex-xl">{fromNano(totalPayOut)}</p>
        </div>
      </div>
      {/* table */}
      <div className="flex flex-col p-4 bg-button-background rounded-md">
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(0, 3).map((prize) => (
            <RankRow className="text-white text-sm" {...prize} key={prize.rank} />
          ))}
        </div>
        <div className="w-full border border-solid my-4" />
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(3).map((prize) => (
            <RankRow className="text-icon text-xs" {...prize} key={prize.rank} />
          ))}
        </div>
      </div>
    </div>
  );
};

const RankRow = ({ rank, amount, className }: { rank: string; amount: string; className?: string }) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <p>{rank}</p>
      <div className="flex gap-1 justify-center items-center">
        <TonIcon />
        <p>{fromNano(amount)}</p>
      </div>
    </div>
  );
};
