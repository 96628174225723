import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

/**
 * Calculate the time remaining until a given epoch time in a "DD:HH:MM:SS" format.
 *
 * @param deadlineTime - The Date object to calculate the deadline for.
 * @returns A formatted string representing the time left until the deadline.
 */
const calcDeadlineTime = (deadlineTime: Date): string => {
  const now = new Date();
  const deadline = new Date(deadlineTime);

  if (deadline <= now) {
    return "00:00:00:00";
  }

  const days = differenceInDays(deadline, now);
  const hours = differenceInHours(deadline, now) % 24;
  const minutes = differenceInMinutes(deadline, now) % 60;
  const seconds = differenceInSeconds(deadline, now) % 60;

  return [days, hours, minutes, seconds].map((unit) => unit.toString().padStart(2, "0")).join(":");
};

export const useCountDown = (epochEndTime: Date) => {
  const [deadlineTime, setDeadlineTime] = useState<string>("");

  useEffect(() => {
    const calculateAndSetDeadline = () => {
      const deadline = calcDeadlineTime(epochEndTime);
      setDeadlineTime(deadline);
    };

    calculateAndSetDeadline();
    const interval = setInterval(calculateAndSetDeadline, 1000);

    return () => clearInterval(interval);
  }, [epochEndTime]);

  return {
    deadlineTime,
  };
};
