import { env } from "@/config/env";
import romanApi from "@/lib/net/romanApi";
import prepareTransactionWithComment from "@/lib/ton/prepareTransactionWithComment";
import { getErrorMessage } from "@/utils/error";
import { CHAIN, type TonConnectUI, useTonConnectUI } from "@tonconnect/ui-react";
import { useState } from "react";

export type DepositError =
  | { type: "create"; message: string }
  | { type: "sendTon"; message: string }
  | { type: "initiate"; message: string };

export interface CreateDepositArg {
  srcUserAddress: string;
  nanoTon: string;
  userId: string;
}

interface CreateDepositResponse {
  id: string;
  userId: string;
  nanoTon: string;
  srcUserAddress: string;
  destAdminAddress: string;
  transactionComment: string;
}

export interface UpdateDepositArg {
  id: string;
  userId: string;
  nanoTon: string;
  srcUserAddress: string;
  destAdminAddress: string;
}

interface UpdateDepositResponse {
  id: string;
}

const createDeposit = async (args: CreateDepositArg): Promise<CreateDepositResponse> => {
  try {
    return await romanApi.post<CreateDepositResponse, CreateDepositArg>("/payment/deposit-tx", args);
  } catch (error: any) {
    throw { type: "create", message: `Create failed: ${getErrorMessage(error)}` } as DepositError;
  }
};
const sendTransaction = async (tonConnectUI: TonConnectUI, args: UpdateDepositArg, txComment: string) => {
  try {
    const network = env.TON_NETWORK === "mainnet" ? CHAIN.MAINNET : CHAIN.TESTNET;
    const depositTransaction = prepareTransactionWithComment(args.destAdminAddress, args.nanoTon, txComment, network);
    await tonConnectUI.sendTransaction(depositTransaction);
  } catch (error: any) {
    await cancelDeposit(args);
    throw { type: "sendTon", message: error.message } as DepositError;
  }
};
const cancelDeposit = async (args: UpdateDepositArg) => {
  try {
    await romanApi.patch<UpdateDepositResponse, UpdateDepositArg>(`/payment/deposit-tx/${args.id}/cancel`, args);
  } catch (error: any) {
    console.error(`Failed to cancel deposit with ID ${args.id}:`, error.message);
  }
};

const initiateDepositInner = async (args: UpdateDepositArg) =>
  await romanApi.patch<UpdateDepositResponse, UpdateDepositArg>(`/payment/deposit-tx/${args.id}/initiate`, args);

const initiateDeposit = async (args: UpdateDepositArg) => {
  try {
    await initiateDepositInner(args);
  } catch (error: any) {
    // TODO: refactor
    try {
      // retry in case of failure
      await initiateDepositInner(args);
    } catch (error: any) {
      throw {
        type: "initiate",
        message: `Initiation failed: ${getErrorMessage(error)}: please contact the administrators. Deposit tx id: ${args.id}`,
      } as DepositError;
    }
  }
};

export const useDeposit = (handleError: (err: DepositError) => void) => {
  const [tonConnectUI] = useTonConnectUI();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const triggerDeposit = async (args: CreateDepositArg) => {
    if (isLoading) {
      return;
    }
    setIsSuccess(false);
    setIsLoading(true);
    try {
      const createDepositData = await createDeposit(args);
      await sendTransaction(
        tonConnectUI,
        {
          id: createDepositData.id,
          userId: createDepositData.userId,
          nanoTon: createDepositData.nanoTon,
          srcUserAddress: createDepositData.srcUserAddress,
          destAdminAddress: createDepositData.destAdminAddress,
        },
        createDepositData.transactionComment,
      );
      await initiateDeposit({
        id: createDepositData.id,
        userId: createDepositData.userId,
        nanoTon: createDepositData.nanoTon,
        srcUserAddress: createDepositData.srcUserAddress,
        destAdminAddress: createDepositData.destAdminAddress,
      });
      setIsSuccess(true);
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    tonConnectUI,
    triggerDeposit,
    isSuccess,
    isLoading,
  };
};
