import { type ReactNode, createContext, useContext } from "react";
import { useCallback, useState } from "react";
import { ErrorModal } from "../components/ErrorModal";

interface ModalContextInterface {
  openModal: (title: string, message?: string) => void;
}

const ErrorModalContext = createContext<ModalContextInterface | null>(null);

export const useErrorModalContext = (): ModalContextInterface => {
  const context = useContext(ErrorModalContext);
  if (!context) {
    throw new Error("useErrorModalContext must be used within a ModalProvider");
  }
  return context;
};

export const ErrorModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { openModal, ModalUI } = useErrorModal(); // Get modal hook

  return (
    <ErrorModalContext.Provider value={{ openModal }}>
      {children}
      {ModalUI}
    </ErrorModalContext.Provider>
  );
};

const useErrorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<{ title: string; message?: string }>({
    title: "",
  });

  const openModal = useCallback((title: string, message?: string) => {
    setModalProps({ title, message });
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ModalUI = isOpen ? (
    <ErrorModal title={modalProps.title} message={modalProps.message} onClose={closeModal} />
  ) : null;

  return {
    openModal,
    ModalUI,
  };
};
