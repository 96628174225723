import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { RoundDetails } from "@/types/RoundDetails";
import { useEffect } from "react";
import useSWR, { type KeyedMutator } from "swr";

export interface RoundResponse {
  round: RoundDetails;
}
interface UseSoccerRoundReturn {
  data?: { round: RoundDetails };
  isLoading: boolean;
  mutate: KeyedMutator<RoundResponse>;
}

const fetcher = (url: string) => romanApi.get<RoundResponse>(url);

export const useSoccerRound = (id: string, handleError: (err: RomanApiError) => void): UseSoccerRoundReturn => {
  const { data, error, isLoading, mutate } = useSWR<RoundResponse, RomanApiError>(`/soccer/rounds/${id}`, fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const homePlayers =
    data?.round.players.homePlayers.map((p1) => ({ ...p1, profileImageUrl: "/temp/player1.png" })) ?? [];
  const awayPlayers =
    data?.round.players.awayPlayers.map((p2) => ({ ...p2, profileImageUrl: "/temp/player2.png" })) ?? [];

  return {
    data: data ? { round: { ...data?.round, players: { homePlayers, awayPlayers } } } : undefined,
    isLoading,
    mutate,
  };
};
