import { Center } from "@/components/Center";
import { TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { usePrizePayout } from "@/hooks/soccer/usePrizePayout";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { RoundEntryPrize } from "@/types/RoundEntryPrize";
import { getErrorMessage } from "@/utils/error";
import { fromNano } from "@ton/core";
import { useCallback } from "react";
import { transformPrizeAmounts } from "../RoundInfo/Prize";
import type { EntryProps } from "./index";

export const Prize = ({
  round,
  totalFee,
  enterButton,
}: EntryProps & {
  totalFee: number;
  enterButton: React.ReactNode;
}) => {
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting rounds", message);
    },
    [openModal],
  );
  const { data, isLoading } = usePrizePayout(round.id, handleError);

  if (isLoading)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  if (!data) {
    return <div>No data to show</div>;
  }

  const { roundEntryPrize } = data;

  return (
    <div className="flex flex-col h-full py-4">
      <div className="flex justify-end mb-8">
        <TonUsdPill />
      </div>

      <Tabs>
        {roundEntryPrize.map((entry) => (
          <Tabs.Item
            title={entry.entryFee === "0" ? "Free" : `${fromNano(entry.entryFee)} TON`}
            key={entry.roundEntryUnitId}
          >
            <PrizeContent {...entry} />
          </Tabs.Item>
        ))}
      </Tabs>

      <div className="flex flex-col gap-6 mt-6">
        <div className="flex flex-col w-full justify-center items-center text-white">
          <p className="text-xs">Total Entry fee</p>
          <div className="flex gap-1">
            <TonIcon />
            <p className="text-white text-xl">{fromNano(totalFee)}</p>
          </div>
        </div>
        {enterButton}
      </div>
    </div>
  );
};

const PrizeContent = ({ totalPayOut, prize }: RoundEntryPrize) => {
  const transformedPrizes = transformPrizeAmounts(prize);

  return (
    <div>
      <div className="flex justify-between py-4">
        <p className="textsm text-white">Prize Payouts</p>
        <div className="flex gap-1">
          <TonIcon />
          <p className="text-white tex-xl">{fromNano(totalPayOut)}</p>
        </div>
      </div>
      {/* table */}
      <div className="flex flex-col p-4 bg-button-background rounded-md">
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(0, 3).map((prize) => (
            <RankRow className="text-white text-sm" {...prize} key={prize.rank} />
          ))}
        </div>
        <div className="w-full border border-solid my-4" />
        <div className="flex flex-col gap-3">
          {transformedPrizes.slice(3).map((prize) => (
            <RankRow className="text-icon text-xs" {...prize} key={prize.rank} />
          ))}
        </div>
      </div>
    </div>
  );
};

const RankRow = ({ rank, amount, className }: { rank: string; amount: string; className?: string }) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <p className="w-full">{rank}</p>
      <div className="flex gap-1 justify-end items-center">
        <TonIcon />
        <p>{fromNano(amount)}</p>
      </div>
    </div>
  );
};
