import { Center } from "@/components/Center";
import { CheckIcon, CheckSuccessfulIcon } from "@/components/Icons";
import { TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useUser } from "@/hooks/useUser";
import type { EntryResult } from "@/types/result";
import { formatNanoTon } from "@/utils/formatNanoTon";
import { countBy } from "lodash";
import { useState } from "react";

interface UserTabProps {
  entries: EntryResult[];
}

const modifyDuplicateUserEntries = (entries: EntryResult[], userId: string) => {
  const userCounts = countBy(entries, "userId");
  const counter = new Map<string, number>();
  return entries.map((entry) => {
    const hasDuplicate = userCounts[entry.userId] > 1;
    if (!hasDuplicate) {
      return { ...entry, isYourTeam: entry.userId === userId };
    }
    const count = (counter.get(entry.userId) || 0) + 1;
    counter.set(entry.userId, count);

    return {
      ...entry,
      userName: `${entry.userName}(${count})`,
      isYourTeam: entry.userId === userId,
    };
  });
};

export const UserTable = ({ entries }: UserTabProps) => {
  const [isOnlyYourTeam, setIsOnlyYourTeam] = useState(false);
  const { user } = useUser();

  if (!user) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }
  const userId = user.id;

  const maxFantasyPoint = Math.max(...entries.map((user) => user.fantasyPoint));
  const formattedEntries = modifyDuplicateUserEntries(entries, userId);
  const displayedEntries = isOnlyYourTeam
    ? formattedEntries.filter((entry) => entry.userId === userId)
    : formattedEntries;

  return (
    <div>
      <div className="flex w-full justify-between items-center py-4">
        <div className="flex gap-1 hover:cursor-pointer" onClick={() => setIsOnlyYourTeam(!isOnlyYourTeam)}>
          {isOnlyYourTeam ? <CheckSuccessfulIcon className="w-4 h-4" /> : <CheckIcon className="w-4 h-4" />}
          <p className="text-xs text-icon">Show only your team</p>
        </div>
        <TonUsdPill />
      </div>
      <div className="flex flex-col gap-1">
        {displayedEntries
          .sort((a, b) => a.rank - b.rank)
          .map((user) => (
            <UserRow {...user} maxFantasyPoint={maxFantasyPoint} key={user.id} />
          ))}
      </div>
    </div>
  );
};

export const setRankColor = (rank: number) => {
  switch (rank) {
    case 1:
      return "bg-gradient-to-b from-[#FFE500] to-[#3D4700] bg-clip-text text-transparent text-base font-bold";
    case 2:
      return "bg-gradient-to-b from-[#FFFFFF] to-[#414141] bg-clip-text text-transparent text-base font-bold";
    case 3:
      return "bg-gradient-to-b from-[#CBA080] to-[#693F0D] bg-clip-text text-transparent text-base font-bold";
    default:
      return "text-icon text-xs";
  }
};

const UserRow = ({
  userName,
  rank,
  prize,
  fantasyPoint,
  maxFantasyPoint,
  isYourTeam,
}: EntryResult & { maxFantasyPoint: number; isYourTeam: boolean }) => {
  const width = (fantasyPoint / maxFantasyPoint) * 130; // 130px is the max width

  return (
    <div
      className={`flex justify-between items-center text-icon text-sm py-2.5 px-3 ${isYourTeam && "bg-gradient-to-r from-[#2A2A2A] to-[#2A2A2A]/0"}`}
    >
      <div className="flex items-center gap-2.5">
        <p className={setRankColor(rank)}>{rank}</p>
        {/* TODO: need image from backend */}
        {/* <img className="rounded-lg" src={image} alt={userName} /> */}
        <div className="flex flex-col gap-2">
          {isYourTeam ? (
            <div className="flex gap-1 items-center">
              <CheckSuccessfulIcon className="w-4 h-4" />
              <p className="text-sm">{userName}</p>
            </div>
          ) : (
            <p className="text-sm">{userName}</p>
          )}
          <div className="h-0.5 bg-icon" style={{ width: `${width}px` }} />
        </div>
      </div>
      <div className="grid grid-cols-2 items-center w-[35%]">
        <div className="">{fantasyPoint.toFixed(2)}pt</div>
        <div className="flex items-center gap-1">
          <TonIcon className="w-4 h-4" />
          <span className="text-center">{formatNanoTon(prize, 4)}</span>
        </div>
      </div>
    </div>
  );
};
