import { TonUsdPill } from "@/components/TonUsdPill";
import type { UserRoundEntryUnit } from "@/types/UserRoundEntryUnit";
import { fromNano } from "@ton/ton";
import { useNavigate } from "react-router-dom";

export const RoundEntryUnits = ({ entryUnits }: { entryUnits: UserRoundEntryUnit[] }) => {
  const navigate = useNavigate();
  if (entryUnits.length === 0) {
    return <div className="flex justify-center items-center h-36 w-full text-center text-white">No data to show</div>;
  }
  const roundStatus = entryUnits[0].status;
  return (
    <div>
      <div className="flex justify-end my-5">
        <TonUsdPill />
      </div>
      <div>
        <div className="grid grid-cols-[150px_1fr_1fr_1fr] text-xs text-[#878787] col-span-4 mb-3">
          <p>Round</p>
          <p>Total Prize</p>
          <p>Entries</p>
          <p>{roundStatus === "prizeDistributed" ? "Prize Won" : "Entry Fee"}</p>
        </div>
        {entryUnits.map((entryUnit) => (
          <RoundEntryUnitRow
            onClick={() => {
              if (entryUnit.status === "schedule") {
                // go the edit page
              } else {
                const matchName = entryUnit.sportEventName;
                //TODO: https://app.asana.com/0/1207835129689105/1208583149556866/f
                navigate(`/results/${entryUnit.id}?matchName=${encodeURIComponent(matchName)}`);
              }
            }}
            entryUnit={entryUnit}
            key={entryUnit.id}
          />
        ))}
      </div>
    </div>
  );
};

const RoundEntryUnitRow = ({ entryUnit, onClick }: { entryUnit: UserRoundEntryUnit; onClick: () => void }) => {
  if (entryUnit.status === "cancelled") {
    return (
      <div className="flex justify-between text-text-disabled pb-4">
        <p className="max-w-[150px] text-sm">{entryUnit.sportEventName}</p>
        <p className="text-xs pr-5">Canceled</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[150px_1fr_1fr_1fr] text-white text-sm pb-4 hover:cursor-pointer" onClick={onClick}>
      <p className="pr-1 self-center justify-self-center">{entryUnit.sportEventName}</p>
      <p className="self-center justify-self-center">{fromNano(entryUnit.prizePool)}</p>
      <p className="self-center justify-self-center">{entryUnit.numEntries}</p>
      <p className="self-center justify-self-center">
        {entryUnit.status === "schedule" || entryUnit.status === "live"
          ? fromNano(entryUnit.entryFee)
          : fromNano(entryUnit.prizeWon ?? 0)}
      </p>
    </div>
  );
};
