import romanApi from "@/lib/net/romanApi";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useTelegram } from "./useTelegram";
const POLLING_INTERVAL = 5000;
interface ResponseUser {
  user: User;
}

interface GetUserRequest {
  telegramUserId: number;
}

const fetchUser = (url: string, telegramUserId: number) => {
  return romanApi.get<ResponseUser, GetUserRequest>(url, { telegramUserId });
};

interface CreateUserRequest {
  telegramUserId: number;
  name: string;
}
export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const { telegramUserId, name } = useTelegram();

  const { data, mutate } = useSWR<ResponseUser>(
    ["/user", telegramUserId],
    ([url, telegramUserId]) => fetchUser(url, Number(telegramUserId)),
    { revalidateOnFocus: false }, // Disable auto-revalidation on window focus
  );

  useEffect(() => {
    if (!data) return;

    if (data.user) {
      setUser(data.user);
      return;
    }

    createUser();
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (telegramUserId) {
        mutate();
      }
    }, POLLING_INTERVAL); // 5 seconds interval

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [telegramUserId, mutate]);

  const createUser = async () => {
    if (!telegramUserId || !name) return;

    const data = await romanApi.post<ResponseUser, CreateUserRequest>("/user", {
      telegramUserId,
      name,
    });

    setUser(data.user);
  };

  return {
    user,
    setUser,
  };
};
