import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { CustomModal } from "@/components/CustomModal";
import { CheckSuccessfulIcon } from "@/components/Icons";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { type DepositError, useDeposit } from "@/hooks/payment/useDeposit";
import { toNano } from "@ton/ton";
import { useTonAddress } from "@tonconnect/ui-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "./CustomInput";
import { OptionButton } from "./OptionButton";

const depositOptions = [5, 10, 50, 100];

export const Deposit = () => {
  const address = useTonAddress();

  const user = useUserContext();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { openModal } = useErrorModalContext();

  const handleDepositError = useCallback(
    (error: DepositError) => {
      openModal(error.message);
    },
    [openModal],
  );
  const { tonConnectUI, triggerDeposit, isSuccess } = useDeposit(handleDepositError);

  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [isInvalidValue, setIsInvalidValue] = useState(true);

  const handleChange = (val: string) => {
    try {
      const nanoValue = toNano(val);
      if (nanoValue <= 0n) {
        throw new Error("Value must be positive");
      }
      setIsInvalidValue(false);
    } catch (error) {
      setIsInvalidValue(true);
    }
    setValue(val);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessModalOpen(true);
    }
  }, [isSuccess]);

  return (
    <div className="flex flex-col gap-3 h-full pt-4">
      <div className="flex items-end gap-2">
        <CustomInput value={value} onChange={handleChange} />
        <p className="text-white">Ton</p>
      </div>
      <div className="flex gap-2 items-center">
        {depositOptions.map((option) => (
          <OptionButton option={option} onClick={() => setValue(option.toString())} key={option} />
        ))}
      </div>
      <div className="fixed bottom-6 left-0 w-full px-6 flex flex-col justify-end">
        {address ? (
          <div className="flex items-center justify-between w-full mb-3">
            <div className="flex items-center gap-2">
              <CheckSuccessfulIcon />
              <div>
                <p className="text-xs text-icon">Wallet address</p>
                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-white text-xs w-52">{address}</p>
              </div>
            </div>
            <button
              type="button"
              className="px-4 py-2.5 rounded-lg bg-gray-500 text-white text-xs"
              onClick={() => tonConnectUI.disconnect()}
            >
              Disconnect
            </button>
          </div>
        ) : (
          <p className="text-white text-xs text-center mb-3">Connect wallet you want to deposit from</p>
        )}

        {address ? (
          <PrimaryButton
            className="w-full align-end"
            text="DEPOSIT"
            disabled={isInvalidValue}
            onClick={() => {
              triggerDeposit({
                srcUserAddress: address,
                nanoTon: toNano(value).toString(),
                userId: user.id,
              });
            }}
          />
        ) : (
          <PrimaryButton
            className="w-full align-end"
            text="CONNECT WALLET"
            onClick={() => {
              tonConnectUI.openModal();
            }}
          />
        )}
      </div>

      <CustomModal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)}>
        <div className="flex flex-col text-white text-center justify-center items-center gap-3">
          <img className="w-16 h-16" src="/assets/success-icon.svg" alt="success icon" />
          <p>Deposit successfully initiated!</p>
          <PrimaryButton className="w-full mt-10" text="BACK TO THUMBA" onClick={() => navigate("/")} />
        </div>
      </CustomModal>
    </div>
  );
};
